import React from "react";
import Layout from "../../components/Layout";

// eslint-disable-next-line
export default () => (
  <Layout pageTitle="Thank you for Subscribing | Subscribe Sense" pageDescription="Thank you for subscribing to our mailing list.">
    <section className="section">
      <div className="container">
        <div class="bg-white">
          <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:py-16 lg:px-8 lg:py-20">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              <span className="block">You're on the list!</span>
              <span className="block text-2xl text-indigo-600 py-4">Look for an email letting you know what the next steps are to get started with Subscribe Sense</span>
            </h2>
          </div>
        </div>          
      </div>
    </section>
  </Layout>
);